<template>
  <div id="controller">
    <img class="logo" src="https://www.theshadingcompany.ae/wp-content/uploads/2019/11/The-Shading-Company-Logo.png" alt="logo" />
    <button v-for="(b, index) in data" :key="b" class="btn" @click="updatePage(index + 1)">
      {{ b.title.rendered }}
    </button>
  </div>
</template>
<script>
import { renderPage, fullScreen } from "@/firebase";
document.title = 'Showroom Controller';

export default {
  name: "Home",
  data() {
    return {
      data: null,
    };
  },
  // props: ["data"],
  methods: {
    updatePage(pageID) {
      renderPage(pageID);
    },
    fs() {
      fullScreen(true);
    },
  },
  mounted() {
    this.data = this.$root.data;
  },
};
</script>
<style scoped>
#controller {
  padding: 0 25px;
}
button {
  width: 100%;
  margin: 0.5em 0;
  padding: 1em 0;
  background: #0068a1;
  color: #fff;
}
.logo {
  margin: 3em auto 1em auto;
  display: block;
}
</style>
